<template>
  <div>
    <van-divider content-position="left">司机考试信息</van-divider>
    <van-form @submit="onSubmit">
      <van-field
        label="倒库视频"
        :rules="[{ required: true, message: '请上传' }]"
        class="field-video"
      >
        <template #input>
          <div @click="chooseFile('daoku')">
            <van-uploader
              ref="daoku"
              :after-read="(file) => afterRead(file, 'daoku')"
              accept="video/*"
            >
              <van-button plain icon="plus" color="#ff6d41" size="small" />
            </van-uploader>
          </div>
          <p v-if="form.daoku">{{ form.daoku }}</p>
        </template>
      </van-field>
      <van-field
        label="考试登记表"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('kaoshidengjibiao')">
            <van-uploader
              ref="kaoshidengjibiao"
              :after-read="(file) => afterRead(file, 'kaoshidengjibiao')"
            >
              <div class="inner">
                <van-icon v-if="!kaoshidengjibiao" name="photograph" />
                <img
                  v-else
                  class="uploaded-img"
                  :src="kaoshidengjibiao"
                  alt=""
                />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>

      <button :disabled="isSubmiting" class="btn" native-type="submit">
        提交
      </button>
      <!-- <button v-else class="btn" disabled>提交中</button> -->
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button, Divider, Field, Form, Popup, Toast, Uploader } from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(Button)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Popup)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'BasicInfo',
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: { daoku: 'gungungungungungungun.abc', kaoshidengjibiao: '' },
      kaoshidengjibiao: '',
    };
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/lianglian/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        // console.log(res);
        this[current] = url;
        this.form[current] = `/${path}`;
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    async handleSubmit() {
      let postData = { ...this.form };
      this.uploading = Toast.loading({
        message: '提交中',
        forbidClick: true,
      });
      this.isSubmiting = true;
      const data = await this.$axios.post('', postData);
      if (data.success) {
        if (data.data) {
          // window.location.href = data.data;
        } else {
          this.uploading.clear();
        }
      }
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
