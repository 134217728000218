<template>
  <div>
    <van-divider content-position="left">司机体检信息</van-divider>
    <van-form @submit="onSubmit">
      <van-field
        label="体检报告"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('tijianbaogao')">
            <van-uploader
              ref="tijianbaogao"
              :after-read="(file) => afterRead(file, 'tijianbaogao')"
              v-model="form.tijianbaogao"
            >
              <div class="inner">
                <van-icon v-if="!tijianbaogao" name="photograph" />
                <img v-else class="uploaded-img" :src="tijianbaogao" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.b"
        label="体检报告日期"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
        readonly
        @click="show = true"
      />

      <button :disabled="isSubmiting" class="btn" native-type="submit">
        提交
      </button>
      <!-- <button v-else class="btn" disabled>提交中</button> -->

      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="onConfirm"
          @cancel="show = false"
        />
      </van-popup>
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  DatetimePicker,
  Divider,
  Field,
  Form,
  Popup,
  Toast,
  Uploader,
} from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(DatetimePicker)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Popup)
  .use(Toast)
  .use(Uploader);

export default {
  name: 'BasicInfo',
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: {
        tijianbaogao: [],
        b: '',
      },
      tijianbaogao: '',
      show: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
    };
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      this[current] = 'https://img01.yzcdn.cn/vant/tree.jpg';
      this.form[current] = [{ url: `https://img01.yzcdn.cn/vant/tree.jpg` }];
      return;
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/lianglian/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        // console.log(res);
        this[current] = url;
        this.form[current] = [{ url: `/${path}` }];
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    onSubmit(values) {
      console.log('submit', values);
      console.log('form', this.form);
      // for (const [key, value] of Object.entries(this.form)) {
      //   if (typeof value !== 'string') {
      //   }
      // }
    },
    async handleSubmit() {
      let postData = { ...this.form };
      this.uploading = Toast.loading({
        message: '提交中',
        forbidClick: true,
      });
      this.isSubmiting = true;
      const data = await this.$axios.post('', postData);
      if (data.success) {
        if (data.data) {
          // window.location.href = data.data;
        } else {
          this.uploading.clear();
        }
      }
      this.isSubmiting = false;
    },
    onConfirm(value) {
      this.form.b = this.$dayjs(value).format('YYYY-MM-DD');
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
