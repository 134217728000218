<template>
  <div class="TaskDetail">
    <van-nav-bar title="" fixed left-arrow @click-left="$router.back()" />

    <div class="content">
      <p class="name">
        <b>{{ detail.taskName }}</b>
        <van-tag plain type="warning">{{ detail.status_dictTxt }}</van-tag>
      </p>
      <p>任务佣金：{{ detail.amount }}</p>
      <p>发包企业：{{ detail.publishEnt }}</p>
      <p>发布时间：{{ detail.publishTime }}</p>
      <p>开始地址：{{ detail.startAddress }}</p>
      <p>结束地址：{{ detail.endAddress }}</p>
      <p>任务描述：</p>
      <div class="desc" v-html="detail.remark"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Tag } from 'vant';

Vue.use(NavBar).use(Tag);

export default {
  name: 'TaskDetail',
  data() {
    return {
      detail: {},
    };
  },
  created() {
    const { id } = this.$route.query;
    this.getDetail(id);
  },
  methods: {
    async getDetail(id) {
      const data = await this.$axios.post(
        '/tax/wechat/contractUser/yunDa/taskInfo',
        {
          idNo: sessionStorage.getItem('idNo'),
          id: id,
        }
      );
      if (data.success) {
        this.detail = data.data;
      }
    },
  },
};
</script>

<style lang="scss">
.TaskDetail {
  background: #fff;
  min-height: 100vh;
  // background: #f4f6fa;

  .content {
    padding: 0.66rem 0.2rem;
    color: #3d3d3d;

    p {
      min-height: 0.3rem;
      line-height: 0.3rem;
      font-size: 0.14rem;
      word-break: break-all;

      &.name {
        position: relative;
        padding-right: 0.5rem;
        font-size: 0.17rem;
        font-weight: 500;

        .van-tag {
          position: absolute;
          top: 0.07rem;
          right: 0;
        }
      }
    }

    .desc {
      padding-left: 2em;
      line-height: 0.3rem;
    }
  }
}
</style>
