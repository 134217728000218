<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import axios from '@/utils/request.js';

export default {
  name: 'App',
  methods: {},
  created() {
    const idNo = JSON.parse(sessionStorage.getItem('idNo'));
    if (!idNo) {
      this.$router.push('login');
    } else {
      this.$router.push('home');
    }

    this.getPlatformByUrl();
  },
  methods: {
    async getPlatformByUrl() {
      const data = await this.$axios.post(
        '/system/platformController/getPlatformByUrl',
        {
          pcUrl: window.location.origin.includes('localhost')
            ? 'www.aaa.com'
            : window.location.origin,
        }
      );
      if (data.success) {
        let { id, platformName } = data.data;
        axios.defaults.headers.common['platformId'] = id;
        sessionStorage.setItem('platformId', id);
        document.title = platformName;
      }
    },
  },
};
</script>
