import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Profile from '@/pages/Profile';
import TaskDetail from '@/pages/TaskDetail';
import Acquisition from '@/pages/Acquisition';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   redirect: '/login',
    // },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/taskDetail',
      name: 'taskDetail',
      component: TaskDetail,
    },
    {
      path: '/acquisition',
      name: 'acquisition',
      component: Acquisition,
    },
  ],
});

export default router;
