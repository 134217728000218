<template>
  <div class="Profile">
    <div class="user">
      <div class="avatar">
        <img src="../assets/个人默认头像.png" alt="" />
      </div>
      <div class="mobile">
        <span>{{ userInfo.phone }}</span>
      </div>
      <div class="status">
        <van-icon name="star" />{{ statusObj[userInfo.idNoStatus] }}
      </div>
    </div>

    <div class="tabs">
      <van-tabs v-model="active" type="card" color="#ff6d41">
        <van-tab name="1" title="司机资料"></van-tab>
        <van-tab name="2" title="副驾资料"></van-tab>
      </van-tabs>
    </div>

    <div class="others">
      <van-cell-group :border="false">
        <van-cell is-link>
          <template #title>
            <van-icon name="idcard" />
            <span>身份证</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <van-icon name="logistics" />
            <span>驾驶证</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <van-icon name="medal-o" />
            <span>从业证</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <van-icon name="orders-o" />
            <span>体检报告</span>
          </template>
        </van-cell>
        <van-cell is-link v-if="active == 1">
          <template #title>
            <van-icon name="credit-pay" />
            <span>银行卡</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <van-icon name="tosend" />
            <span>考试信息</span>
          </template>
        </van-cell>
        <van-cell is-link>
          <template #title>
            <van-icon name="newspaper-o" />
            <span>代驾合同</span>
          </template>
        </van-cell>
        <van-cell is-link v-if="active == 1">
          <template #title>
            <van-icon name="exchange" />
            <span>转账记录</span>
          </template>
        </van-cell>
      </van-cell-group>
    </div>

    <NavBar :activeNav="'profile'" />
  </div>
</template>

<script>
import Vue from 'vue';
import { Cell, CellGroup, Tab, Tabs, Toast } from 'vant';
import NavBar from '../components/NavBar.vue';

Vue.use(Cell).use(CellGroup).use(Tab).use(Tabs).use(Toast);

export default {
  name: 'Profile',
  components: {
    NavBar,
  },
  data() {
    return {
      statusObj: {
        0: '未认证',
        1: '已认证',
        2: '认证失败',
      },
      userInfo: {},
      active: 1,
    };
  },
  created() {
    this.userInfo = {
      idNoStatus: 1,
      phone: '15000105733',
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Profile {
  min-height: 100vh;

  .user {
    height: 1.4rem;
    background: url('../assets/profile_o.png') no-repeat center top / 100%;
    padding-top: 0.32rem;
    padding-left: 0.26rem;
    color: #fff;
    box-sizing: border-box;

    .avatar {
      float: left;
      display: block;
      width: 0.74rem;
      height: 0.74rem;
      background: hsla(0, 0%, 100%, 0.16);
      margin-right: 0.12rem;
      padding: 0.05rem;
      border-radius: 50%;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mobile {
      height: 0.22rem;
      line-height: 0.22rem;
      margin: 0.1rem 0;
      font-size: 0.2rem;

      span + span {
        font-size: 0.12rem;
      }
    }

    .status {
      display: inline-block;
      height: 0.22rem;
      line-height: 0.22rem;
      background: rgba(#fff, 0.3);
      padding: 0 0.1rem;
      border-radius: 0.11rem;
      font-size: 0.12rem;
      vertical-align: top;
    }
  }

  .tabs {
    margin-top: 0.12rem;
    .van-tabs--card > .van-tabs__wrap {
      height: auto;
    }

    .van-tabs__nav--card {
      height: auto;
      border-radius: 0.06rem;
    }

    .van-tab {
      line-height: 0.32rem;
    }
  }

  .others {
    background: #fff;
    margin: 0.2rem 0.16rem 0.74rem;
    padding: 0.1rem 0;
    border-radius: 0.06rem;

    .van-cell__title {
      .van-icon {
        margin: 0.03rem 0.1rem;
        margin-left: 0;
        color: $orange;
        font-size: 0.18rem;
        vertical-align: top;
      }

      span {
        display: inline-block;
        font-size: 0.14rem;
        vertical-align: top;
      }

      a {
        position: relative;
        display: block;
        width: 100%;

        .van-icon-arrow {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
          color: #969799;
        }
      }
    }
  }
}
</style>
