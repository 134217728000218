<template>
  <div>
    <van-divider content-position="left">司机基本信息</van-divider>
    <van-form @submit="onSubmit">
      <van-field
        label="驾驶证正面"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('jiashizhenga')">
            <van-uploader
              ref="jiashizhenga"
              :after-read="(file) => afterRead(file, 'jiashizhenga')"
            >
              <div class="inner">
                <van-icon v-if="!jiashizhenga" name="photograph" />
                <img v-else class="uploaded-img" :src="jiashizhenga" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        label="驾驶证反面"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('jiashizhengb')">
            <van-uploader
              ref="jiashizhengb"
              :after-read="(file) => afterRead(file, 'jiashizhengb')"
            >
              <div class="inner">
                <van-icon v-if="!jiashizhengb" name="photograph" />
                <img v-else class="uploaded-img" :src="jiashizhengb" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>

      <van-field
        label="从业资格证1"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('congyezigea')">
            <van-uploader
              ref="congyezigea"
              :after-read="(file) => afterRead(file, 'congyezigea')"
            >
              <div class="inner">
                <van-icon v-if="!congyezigea" name="photograph" />
                <img v-else class="uploaded-img" :src="congyezigea" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>
      <van-field
        label="从业资格证2"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('congyezigeb')">
            <van-uploader
              ref="congyezigeb"
              :after-read="(file) => afterRead(file, 'congyezigeb')"
            >
              <div class="inner">
                <van-icon v-if="!congyezigeb" name="photograph" />
                <img v-else class="uploaded-img" :src="congyezigeb" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>

      <van-field
        label="银行卡"
        :rules="[{ required: true, message: '请上传' }]"
      >
        <template #input>
          <div class="item-image" @click="chooseFile('yinhangka')">
            <van-uploader
              ref="yinhangka"
              :after-read="(file) => afterRead(file, 'yinhangka')"
            >
              <div class="inner">
                <van-icon v-if="!yinhangka" name="photograph" />
                <img v-else class="uploaded-img" :src="yinhangka" alt="" />
              </div>
            </van-uploader>
          </div>
        </template>
      </van-field>

      <van-field
        v-model="form.name"
        label="姓名"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.idNo"
        label="身份证号"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.bankNo"
        label="银行卡号"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.bankName"
        label="所属银行"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.a"
        label="驾驶证有效期"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="form.b"
        label="从业证有效期"
        placeholder="请输入"
        :rules="[{ required: true, message: '' }]"
      />

      <button :disabled="isSubmiting" class="btn" native-type="submit">
        提交
      </button>
      <!-- <button v-else class="btn" disabled>提交中</button> -->
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider, Field, Form, Toast, Uploader } from 'vant';
import OSS from 'ali-oss';
import Compressor from 'compressorjs';
import { getOssToken, getOssFile } from '@/utils/getOss';

Vue.use(Divider).use(Field).use(Form).use(Toast).use(Uploader);

export default {
  name: 'BasicInfo',
  data() {
    return {
      clientOSS: {},
      isSubmiting: false,
      uploading: false,
      form: {
        jiashizhenga: '',
        jiashizhengb: '',
        congyezigea: '',
        congyezigeb: '',
        yinhangka: '',
        name: '',
        idNo: '',
        bankNo: '',
        bankName: '',
        a: '',
        b: '',
      },
      jiashizhenga: '',
      jiashizhengb: '',
      congyezigea: '',
      congyezigeb: '',
      yinhangka: '',
    };
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    chooseFile(type) {
      this.$refs[type].chooseFile();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/lianglian/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        // console.log(res);
        this[current] = url;
        this.form[current] = `/${path}`;
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    onSubmit(values) {
      console.log('submit', values);
    },
    async handleSubmit() {
      let postData = { ...this.form };
      this.uploading = Toast.loading({
        message: '提交中',
        forbidClick: true,
      });
      this.isSubmiting = true;
      const data = await this.$axios.post('', postData);
      if (data.success) {
        if (data.data) {
          // window.location.href = data.data;
        } else {
          this.uploading.clear();
        }
      }
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss">
// @import '@/styles/vars';
</style>
