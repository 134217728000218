<template>
  <div class="Acquisition">
    <van-steps :active="active" active-color="#ff6d41">
      <van-step>基本信息</van-step>
      <van-step>体检信息</van-step>
      <van-step>考试信息</van-step>
      <van-step>代驾合同</van-step>
      <van-step>转账记录</van-step>
    </van-steps>

    <!-- <BasicInfo /> -->
    <MedicalInfo />
    <!-- <ExamInfo /> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { Divider, Field, Step, Steps, Toast, Uploader } from 'vant';
import BasicInfo from '@/components/BasicInfo.vue';
import MedicalInfo from '@/components/MedicalInfo.vue';
import ExamInfo from '@/components/ExamInfo.vue';

Vue.use(Divider).use(Field).use(Step).use(Steps).use(Toast).use(Uploader);

export default {
  name: 'Acquisition',
  components: { BasicInfo, MedicalInfo, ExamInfo },
  data() {
    return {
      active: 0,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Acquisition {
  min-height: 100vh;
  background: #fff;
  padding: 0 0.16rem 0.32rem;

  .item-image {
    position: relative;
    z-index: 10;
    width: 0.8rem;
    height: 0.8rem;

    .van-uploader {
      z-index: -10;
      pointer-events: none;
    }

    .van-uploader__preview {
      display: none;
    }

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 0.8rem;
      height: 0.8rem;
      background-color: #f7f8fa;

      img {
        width: 100%;
        height: 100%;
      }

      .van-icon {
        color: #dcdee0;
        font-size: 0.24rem;
      }
    }
  }

  .field-video {
    .van-field__control--custom {
      display: block;
    }

    & + p {
      margin-left: 0.12rem;
      line-height: 0.2rem;
      word-break: break-all;
    }
  }

  .btn {
    display: block;
    width: 100%;
    height: 0.44rem;
    background-color: $orange;
    margin: 0.32rem auto 0;
    padding: 0;
    border: none;
    border-radius: 0.22rem;
    color: #fff;
    font-size: 0.17rem;
    box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

    &:disabled {
      background-color: $gray !important;
    }
  }
}
</style>
