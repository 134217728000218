<template>
  <div class="Login">
    <div class="form">
      <h1>登录</h1>

      <van-field
        v-model="form.idNo"
        label=""
        maxlength="18"
        placeholder="请输入身份证号"
      ></van-field>

      <van-field
        v-model="form.mobile"
        label=""
        maxlength="11"
        placeholder="请输入手机号码"
        type="tel"
      ></van-field>

      <van-field
        v-model="form.smsCode"
        label=""
        maxlength="4"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            v-if="!counting"
            :disabled="form.mobile.length < 11"
            @click="getCode"
            class="send"
            plain
            size="small"
            type="default"
            >获取验证码</van-button
          >
          <span v-if="counting" class="count-down">{{ seconds }}s后可重发</span>
        </template>
      </van-field>

      <van-button
        block
        class="submit"
        type="info"
        :disabled="disabled || submiting"
        @click="handleSubmit"
        >登录
      </van-button>

      <p>
        沪ICP备13007528号-2<br />
        沪公网安备 31011502014561号
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import axios from '@/utils/request.js';
import { Button, Field, Toast } from 'vant';
// import getQueryParams from '../utils/getQueryParams';
Vue.use(Button).use(Field).use(Toast);

export default {
  name: 'Login',
  data() {
    return {
      form: {
        idNo: '',
        mobile: '',
        smsCode: '',
      },
      seconds: 60,
      counting: false,
      submiting: false,
    };
  },
  computed: {
    disabled() {
      return !this.form.idNo || !this.form.mobile || !this.form.smsCode;
    },
  },
  methods: {
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.mobile)) {
        Toast({
          message: '请输入正确格式的手机号码',
        });
        return;
      }
      this.form.smsCode = '';
      this.counting = true;
      this.seconds = 60;
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/tax/wechat/contractUser/yunDa/sendMsg', {
        phone: this.form.mobile,
      });
      if (data.success) {
      }
    },
    async handleSubmit() {
      this.submiting = true;
      const data = await this.$axios.post(
        '/tax/wechat/contractUser/yunDa/login',
        {
          ...this.form,
        }
      );
      if (data.success) {
        sessionStorage.setItem('idNo', data.data);
        this.$router.push('home');
      }
      this.submiting = false;
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Login {
  min-height: 100vh;
  background: $orange;

  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.63rem;
    background: #fff;
    padding: 0.28rem;
    border-radius: 0.06rem;
    transform: translateX(-50%) translateY(-50%);

    .van-cell {
      padding: 0.12rem 0;
      border-radius: 0.06rem;

      & + .van-cell {
        margin-top: 0.08rem;
      }

      &::after {
        content: none;
      }

      .van-field__control {
        font-size: 0.16rem;

        &::placeholder {
          color: #aaa;
        }
      }
    }

    .send {
      height: 0.32rem;
      border-color: $orange;
      color: $orange;
      font-size: 0.14rem;
    }

    .count-down {
      height: 0.32rem;
      line-height: 0.32rem;
    }

    .submit {
      width: 100%;
      height: 0.42rem;
      background: $orange;
      margin: 0.32rem 0 0;
      border: none;
      border-radius: 0.06rem;
      font-size: 0.18rem;
    }

    h1 {
      position: absolute;
      right: 0;
      top: -0.7rem;
      left: 0;
      color: #fff;
      font-size: 0.24rem;
      font-weight: normal;
      text-align: center;
    }

    p {
      position: absolute;
      right: 0;
      bottom: -0.5rem;
      left: 0;
      text-align: center;
      color: rgba(#fff, 0.6);
      font-size: 0.12rem;
      text-align: center;
    }
  }
}
</style>
