<template>
  <div class="Home">
    <div class="banner">
      <van-swipe :autoplay="5000" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in banners" :key="index">
          <div class="img-wrapper">
            <img class="banner-img" :src="item" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="search">
      <van-search
        v-model="value"
        @search="onSearch"
        @cancel="onCancel"
        show-action
      >
        <template #action>
          <div class="btn" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>

    <div class="hot">
      <div class="title">当前推荐</div>
      <div class="recommend-list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            class="item"
            v-for="item in list"
            :key="item.id"
            @click="toTaskDetail(item.id)"
          >
            <div class="name van-ellipsis">{{ item.taskName }}</div>
            <div class="date van-ellipsis">
              派单日期：{{ item.publishTime }}
            </div>
            <div class="desc van-multi-ellipsis--l2">
              任务要求：{{ item.remark }}
            </div>
            <div class="flex">
              <div class="status">【 {{ item.status_dictTxt }} 】</div>
              <div class="amount">{{ item.amount }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <NavBar :activeNav="'home'" />
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import Vue from 'vue';
import { Button, Dialog, List, Search, Swipe, SwipeItem, Toast } from 'vant';
import banner1 from '../assets/banner01.jpg';
import banner2 from '../assets/banner02.jpg';
import banner3 from '../assets/banner03.jpg';

Vue.use(Button)
  .use(Dialog)
  .use(List)
  .use(Search)
  .use(Swipe)
  .use(SwipeItem)
  .use(Toast);

export default {
  name: 'Home',
  components: {
    NavBar,
  },
  data() {
    return {
      banners: [banner1, banner2, banner3],
      value: '',
      list: [],
      pageNo: 1,
      loading: false,
      finished: false,
      total: 0,
    };
  },
  created() {
    // this.getNotice();
  },
  methods: {
    onLoad() {
      this.getTaskList();
    },
    async getTaskList() {
      this.finished = false;
      this.loading = true;
      const data = await this.$axios.post(
        '/tax/wechat/contractUser/yunDa/taskPage',
        {
          page: this.pageNo,
          limit: 10,
          idNo: sessionStorage.getItem('idNo'),
        }
      );
      if (data.success) {
        const { list, total, pages } = data.data;
        this.list = [...this.list, ...list];
        this.total = total;
        if (this.pageNo < pages) {
          this.pageNo++;
        } else {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    onSearch(v) {
      this.pageNo = 1;
      this.list = [];
      this.getTaskList();
    },
    onCancel(v) {
      this.pageNo = 1;
      this.list = [];
      this.getTaskList();
    },
    async getNotice() {
      const data = await this.$axios.post('/tax/wechat/notice/getNoticeFlag', {
        openId: this.userInfo.openId,
      });
      if (data.success && data.data) {
        Dialog.alert({
          title: '签约提示',
          message: '您有一份协议待签约，请按照系统指引完成协议签署和信息采集。',
          confirmButtonText: '确认',
          confirmButtonColor: '#ff6d41',
          closeOnClickOverlay: true,
        })
          .then(() => {
            window.location.href = '';
          })
          .catch((action) => {
            if (action === 'cancel') {
              // do someting
            }
          });
      }
    },
    toTaskDetail(id) {
      this.$router.push({
        name: 'taskDetail',
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/vars';

.Home {
  padding-bottom: 0.58rem;

  .banner {
    min-height: 2.4rem;

    .img-wrapper {
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .search {
    .btn {
      background: $orange;
      padding: 0 0.24rem;
      border-radius: 0.06rem;
      color: #fff;
      font-size: 0.16rem;
    }

    .van-search {
      background: none;
      padding: 0.16rem 0.2rem;

      .van-search__content {
        background: #fff;
        margin-right: 0.12rem;
        border-radius: 0.06rem;
      }

      .van-search__action {
        padding: 0;
      }
    }

    .van-icon-search {
      color: #cdcdcd;
      font-size: 0.22rem;
      font-weight: 600;
    }
  }

  .tips {
    margin-bottom: 0.16rem;
    font-size: 0.16rem;
    text-align: center;
  }

  .hot {
    padding: 0 0.2rem;

    .title {
      margin-bottom: 0.1rem;
      color: #333;
      font-size: 0.2rem;
      font-weight: 500;
    }
  }

  .recommend-list {
    .item {
      position: relative;
      margin-bottom: 0.16rem;
      padding: 0.16rem;
      background: #fff;
      box-shadow: 0 0.04rem 0.2rem 0 rgba(#1d2538, 0.08);
      border-radius: 0.06rem;
      box-sizing: border-box;

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .name {
        line-height: 0.24rem;
        font-size: 0.17rem;
        font-weight: 500;
      }

      .date {
        line-height: 0.2rem;
        margin: 0.1rem 0;
        color: #3d3d3d;
      }

      .desc {
        line-height: 0.2rem;
        margin: 0.1rem 0;
        color: #3d3d3d;
      }

      .period {
        line-height: 0.2rem;
        margin: 0.1rem 0;
        color: #3d3d3d;
      }

      .amount {
        line-height: 0.32rem;
        color: $orange;
        font-size: 0.18rem;
        font-weight: 600;
      }

      .status {
        line-height: 0.32rem;
        color: #ffc107;
      }
    }
  }
}
</style>
